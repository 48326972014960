import React, { useState, useEffect } from 'react';
import './ownersPage.css';

const OwnersPage=({textDataArray, displayLang})=>{
    let tt = textDataArray._owernersPage;


    return(
        <div id='ownersPage'>
            <div className='sectionTitle'>
                <h1>{tt.headerH1[displayLang]}</h1>
                <h2>{tt.headerH2[displayLang]}</h2>
            </div>
            <div className='innerText'>
                <p>{tt.headerP[displayLang]}</p>
            </div>

            <div className='section' id='firstSection'> {/*Keymap*/}
                <h1>{tt.kmH1[displayLang]}</h1>
                <p>{tt.kmP1[displayLang]}</p>
                <img src={require('../../images/65rgb_layer1Keymap.png')} alt="Hide Nothing"/> 
                <p>{tt.kmP2[displayLang]}</p>
                <img src={require('../../images/65rgb_layer2Keymap.png')} alt="Hide Nothing"/> 
                <p className="separator">{tt.kmP3[displayLang]}</p>
                <p className='tableTitle'>{tt.kmPRgb[displayLang]}</p>
                <KeycodeTable
                    textDataArray = {textDataArray}
                    displayLang = {displayLang}
                    keycodeArray = {textDataArray._keymap.RGB}
                />
                <p className='tableTitle'>{tt.kmPNumpad[displayLang]}</p>
                <KeycodeTable
                    textDataArray = {textDataArray}
                    displayLang = {displayLang}
                    keycodeArray = {textDataArray._keymap.Numpad}
                />
                <p className='tableTitle'>{tt.kmPAudio[displayLang]}</p>
                <KeycodeTable
                    textDataArray = {textDataArray}
                    displayLang = {displayLang}
                    keycodeArray = {textDataArray._keymap.Audio}
                />
                <p className='tableTitle'>{tt.kmPOthers[displayLang]}</p>
                <KeycodeTable
                    textDataArray = {textDataArray}
                    displayLang = {displayLang}
                    keycodeArray = {textDataArray._keymap.others}
                />
                <p className="separator">{tt.kmPGeeksL1[displayLang]}</p>
                <img className='keymapc' src={require('../../images/65rgb_layer1JSON.png')} alt="Hide Nothing"/> 
                <p>{tt.kmPGeeksL2[displayLang]}</p>
                <img className='keymapc' src={require('../../images/65rgb_layer2JSON.png')} alt="Hide Nothing"/> 
                <p>{tt.kmPDetails[displayLang]}</p>
                <a href="https://docs.qmk.fm/keycodes" target="_blank"
                rel="noopener noreferrer">{tt.kmQMKwebsite[displayLang]}</a>

            </div>

            <div className='section'> {/*QMK VIA */}
                <h1>{tt.viaH1[displayLang]}</h1>
                <p>{tt.viaP[displayLang]}</p>

                {/* <img src={require('../../images/K000_4.jpg')} alt="Hide Nothing"/> */}
            </div>
            
            <div className='section'> {/*Care and maintenance */}
                <h1>{tt.cmH1[displayLang]}</h1>
                <h3>{tt.cmH2[displayLang]}</h3>
                <p>{tt.cmP1[displayLang]}</p>
                <ol>
                    <li>
                        <span className='bold'>{tt.cmOLBold2[displayLang]}</span>
                        <span>{tt.cmOL2[displayLang]}</span>
                    </li>
                    <li>
                        <span className='bold'>{tt.cmOLBold3[displayLang]}</span>
                        <span>{tt.cmOL3[displayLang]}</span>
                    </li>
                    <li>
                        <span className='bold'>{tt.cmOLBold4[displayLang]}</span>
                        <span>{tt.cmOL4[displayLang]}</span>
                    </li>
                    <li>
                        <span className='bold'>{tt.cmOLBold5[displayLang]}</span>
                        <span>{tt.cmOL5[displayLang]}</span>
                    </li>
                    <li>
                        <span className='bold'>{tt.cmOLBold6[displayLang]}</span>
                        <span>{tt.cmOL6[displayLang]}</span>
                    </li>
                    <li>
                        <span className='bold'>{tt.cmOLBold7[displayLang]}</span>
                        <span>{tt.cmOL7[displayLang]}</span>
                    </li>
                </ol>
                <p>{tt.cmP3[displayLang]}</p>
                {/* <img src={require('../../images/K000_4.jpg')} alt="Hide Nothing"/> */}
            </div>

            <div className='section'> {/*Dépannage */}
                <h1>{tt.troubleShootingH1[displayLang]}</h1>
                <ol>
                    <li>
                        <span className='bold'>{tt.tsBold1[displayLang]}</span>
                        <span>{tt.tsOL1[displayLang]}</span>
                    </li>
                </ol>
            </div>

            <div className='section lastSection' > {/*Contactez nous */}
                <h1>{tt.contactH1[displayLang]}</h1>
                <p>{tt.contactP[displayLang]}</p>
                <button className='btn'>
                    <a href="mailto:info@hnkbd.ca">{textDataArray._footer.contactUsh1[displayLang]}
                    </a>
                </button>
            </div>
        </div>
    )
}

const KeycodeTable=({textDataArray, displayLang, keycodeArray})=>{
    return(
        <table>
            <thead>
                <tr>
                    <th scope="col">{textDataArray._keymap.thCode[displayLang]}</th>
                    <th scope="col">{textDataArray._keymap.thKey[displayLang]}</th>
                    <th scope="col">{textDataArray._keymap.thEffect[displayLang]}</th>
                </tr>
            </thead>
            <tbody>
                {keycodeArray.map((keycode)=>{
                            return(
                            <tr>
                                <td>{keycode.targetKey}</td>
                                <td>{keycode.combination[displayLang]}</td>
                                <td>{keycode.effect[displayLang]}</td>
                            </tr>
                            )
                        })}
            </tbody>
        </table>
    )
}
export{OwnersPage};